import {
  NOTIFICATIONS_LIST,
  NOTIFICATIONS_ACCEPTED,
  NOTIFICATIONS_REJECTED,
  NOTIFICATION_RECEIVED,
} from 'App/store/constants';
const initialState = {
  notifications: [],
  notificationMessage: null,
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NOTIFICATIONS_LIST.PENDING:
      return {...state, loading: true};
    case NOTIFICATIONS_LIST.SUCCESS:
      return {...state, loading: false, notifications: action.payload.data};
    case NOTIFICATIONS_LIST.FAILURE:
      return {
        ...state,
        notifications: [],
        loading: false,
        errors: action.payload,
      };

    case NOTIFICATIONS_ACCEPTED.PENDING:
      return {...state, loading: true};
    case NOTIFICATIONS_ACCEPTED.SUCCESS:
      return {...state, notificationMessage: null, loading: false};
    case NOTIFICATIONS_ACCEPTED.FAILURE:
      return {
        ...state,
        notificationMessage: null,
        loading: false,
        errors: action.payload,
      };

    case NOTIFICATIONS_REJECTED.PENDING:
      return {...state, loading: true};
    case NOTIFICATIONS_REJECTED.SUCCESS:
      return {...state, notificationMessage: null, loading: false};
    case NOTIFICATIONS_REJECTED.FAILURE:
      return {
        ...state,
        notificationMessage: null,
        loading: false,
        errors: action.payload,
      };

    case NOTIFICATION_RECEIVED:
      return {...state, notificationMessage: action.payload};

    default:
      return state;
  }
};

export default reducer;
