import { FAVOURITE_ADDRESS_LIST, FAVOURITE_ADDRESS_DETAILS,FAVOURITE_ADDRESS_DELETE } from 'App/store/constants';
const initialState = {
  favourites: [],
  favourite_address: null,
  favourite_delete: null,
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FAVOURITE_ADDRESS_LIST.PENDING:
      return { ...state, favourites: [], loading: true };
    case FAVOURITE_ADDRESS_LIST.SUCCESS:
      return { ...state, favourites: action.payload.data, loading: false };
    case FAVOURITE_ADDRESS_LIST.FAILURE:
      return { ...state, favourites: [], errors: action.payload, loading: false };
    case FAVOURITE_ADDRESS_DETAILS.PENDING:
      return { ...state, favourite_address: null, loading: true };
    case FAVOURITE_ADDRESS_DETAILS.SUCCESS:
      return { ...state, favourite_address: action.payload.data, loading: false };
    case FAVOURITE_ADDRESS_DETAILS.FAILURE:
      return { ...state, favourite_address: null, errors: action.payload, loading: false };
    case FAVOURITE_ADDRESS_DELETE.PENDING:
        return { ...state, favourite_delete: null, loading: true };
    case FAVOURITE_ADDRESS_DELETE.SUCCESS:
        return { ...state, favourite_delete: action.payload.data, loading: false };
    case FAVOURITE_ADDRESS_DELETE.FAILURE:
        return { ...state, favourite_delete: null, errors: action.payload, loading: false };
    default:
      return state;
  }
};


export default reducer;
