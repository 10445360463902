import { PAGEINFO } from 'App/store/constants';
const initialState = {
    pageinfo: null,
    errors: {},
    loading: false,
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PAGEINFO.PENDING:
            return { ...state, pageinfo: null, loading: true };
        case PAGEINFO.SUCCESS:
            return { ...state, pageinfo: action.payload.data, loading: false };
        case PAGEINFO.FAILURE:
            return { ...state, pageinfo: null, errors: action.payload };
        default:
            return state;
    }
};

export default reducer;
