import {
  LOGIN_DETAILS,
  FETCH_OTP,
  REGISTER_CHECK,
  LOGIN_VERIFY_STEP,
  REGISTER_VERIFY_STEP,
  CLEAR_LOGIN_DATA,
  GLOBAL_DATA,
} from 'App/store/constants';

const initialState = {
  loginDetails: null,
  registerDetails: null,
  loginVerifyStep: null,
  registerVerifyStep: null,
  globalData: null,
  errors: null,
  loading: false,
  data: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GLOBAL_DATA:
      return {...state, globalData: action.payload};

    case LOGIN_DETAILS:
      return {...state, loginDetails: action.payload};

    case LOGIN_VERIFY_STEP:
      return {...state, loginVerifyStep: null};

    case REGISTER_VERIFY_STEP:
      return {...state, registerVerifyStep: null};

    case FETCH_OTP.PENDING:
      return {...state, errors: null, loginVerifyStep: null, loading: true};
    case FETCH_OTP.SUCCESS:
      return {
        ...state,
        loginVerifyStep: true,
        loading: false,
      };
    case FETCH_OTP.FAILURE:
      return {...state, errors: action.payload, loading: false};

    case REGISTER_CHECK.PENDING:
      return {...state, errors: null, registerVerifyStep: null, loading: true};
    case REGISTER_CHECK.SUCCESS:
      const response = action.payload;
      return {
        ...state,
        registerVerifyStep: true,
        registerDetails: {id: response.id, country_code: response.country_code, cust_mobile: response.cust_mobile},
        loading: false,
      };
    case REGISTER_CHECK.FAILURE:
      return {...state, errors: action.payload, loading: false};

    case CLEAR_LOGIN_DATA:
      return {...state, loginDetails: null, registerDetails: null, loginVerifyStep: null, registerVerifyStep: null};
    default:
      return state;
  }
};

export default reducer;
