import {combineReducers} from 'redux';
import auth from 'App/store/reducers/auth.reducer';
import user from 'App/store/reducers/user.reducer';
import masters from 'App/store/reducers/masters.reducer';
import activity from 'App/store/reducers/activity.reducer';
import toast from 'App/store/reducers/toast.reducer';
import address from 'App/store/reducers/address.reducer';
import booking from 'App/store/reducers/booking.reducer';
import common from 'App/store/reducers/common.reducer';
import notifications from 'App/store/reducers/notifications.reducer';
import dashboard from 'App/store/reducers/dashboard.reducer';
import transactions from 'App/store/reducers/transactions.reducer';
import support from 'App/store/reducers/support.reducer';
import settings from 'App/store/reducers/settings.reducer';
import schedule from 'App/store/reducers/schedule.reducer';
import faqs from 'App/store/reducers/faqs.reducer';
import favourites from 'App/store/reducers/FavouriteAddress.reducer';
import pageinfo from 'App/store/reducers/pageInfo.reducer'

const reducerHash = combineReducers({
  user,
  auth,
  masters,
  toast,
  activity,
  address,
  booking,
  common,
  notifications,
  dashboard,
  support,
  schedule,
  transactions,
  settings,
  faqs,
  favourites,
  pageinfo,
});

export type RootState = ReturnType<typeof reducerHash>;
export default reducerHash;

// export default combineReducers(reducerHash);
