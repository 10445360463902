import {
  LOGIN,
  CURRENT_LOCATION,
  REFRESH_TOKEN,
  PROFILE_FETCH,
  PROFILE_SAVE,
  LOGOUT,
  RESEND_OTP,
  DRIVER_STATUS,
  PROFILE_IMAGE,
  FETCH_DRIVER,
  DRIVER_SAVE,
  DRIVER_SENT_APPROVAL,
  DRIVER_FILE,
  GOOGLE_MAPS_LOADED,
  CAR_REGISTER_CHECK,
  CAR_REGISTER_CHECK_NULL,
  REFRESH_ZONE,
  LAST_BOOKING_ZONE_ID,
  EMERGENCY_DETAILS,
  GET_EMERGENCY_DETAILS,
  DELETE_EMERGENCY_DETAILS,
} from "App/store/constants";

const initialState = {
  refreshTokenLoaded: null,
  driverStatus: false,
  loggedIn: false,
  loginError: null,
  currentLocation: null,
  profileData: null,
  resendOTPCount: 0,
  user: <any>null,
  token: null,
  loading: false,
  driverDetails: <any>null,
  sentRequest:null,
  lastFileUploaded: null,
  googleMapLoaded: null,
  vehicleCheckDetails: null,
  emergencyDetails: [],
  zone: <any>null,
  removeDetails: null,
  countryCode: localStorage.getItem("countryCode") || null
};

const storeSessionData = async (response: any) => {
  localStorage.setItem("refreshToken", response.refreshToken);
  localStorage.setItem("countryCode", response.data.user.country_code);
  localStorage.setItem("user", JSON.stringify(response.data.user));
  localStorage.setItem("userToken", response.data.access_token);
};

const clearSessionData = async () => {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("userToken");
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN.PENDING:
      return { ...state, errors: null, loginError: null, user: null, token: null, loggedIn: false, loading: true };
    case LOGIN.SUCCESS:
      const response = action.payload;
      if (response.status === 'success') {
        storeSessionData(response);
        return {
          ...state,
          user: response.data.user,
          token: response.data.access_token,
          loggedIn: true,
          loading: false,
        };
      } else {
        return { ...state, loginError: response.msg, loading: false };
      }
    case LOGIN.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case CURRENT_LOCATION:
      return { ...state, currentLocation: action.payload };

    case GOOGLE_MAPS_LOADED:
      return { ...state, googleMapLoaded: action.payload };

    case REFRESH_ZONE.PENDING:
      return { ...state, errors: null, loading: true };
    case REFRESH_ZONE.SUCCESS:
      return { ...state, zone: action.payload.data, loading: false };
    case REFRESH_ZONE.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case REFRESH_TOKEN.PENDING:
      return { ...state, errors: null, refreshTokenLoaded: false };
    case REFRESH_TOKEN.SUCCESS:
      const res = action.payload;
      if (res.status === "success") {
        storeSessionData(res);
        return {
          ...state,
          user: res.data.user,
          token: res.data.access_token,
          loggedIn: true,
          refreshTokenLoaded: true,
        };
      } else {
        return state;
      }
    case REFRESH_TOKEN.FAILURE:
      return { ...state, errors: action.payload, refreshTokenLoaded: true };

    case PROFILE_FETCH.PENDING:
      return { ...state, errors: null, loading: true };
    case PROFILE_FETCH.SUCCESS:
      return { ...state, profileData: action.payload.data, loading: false };
    case PROFILE_FETCH.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case PROFILE_IMAGE.PENDING:
      return { ...state, errors: null, loading: true };
    case PROFILE_IMAGE.SUCCESS:
      const temp: any = { ...state.user };
      temp.img = action.payload.file;
      return { ...state, user: temp, loading: false };
    case PROFILE_IMAGE.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case RESEND_OTP.PENDING:
      return { ...state, loading: true };
    case RESEND_OTP.SUCCESS:
      return {
        ...state,
        resendOTPCount: state.resendOTPCount + 1,
        loading: false,
      };
    case RESEND_OTP.FAILURE:
      return { ...state, loading: false };

    case DRIVER_STATUS.PENDING:
      return { ...state, errors: null, loading: true };
    case DRIVER_STATUS.SUCCESS:
      return { ...state, driverStatus: action.payload, loading: false };
    case DRIVER_STATUS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case LAST_BOOKING_ZONE_ID.PENDING:
      return { ...state, errors: null, loading: true };
    case LAST_BOOKING_ZONE_ID.SUCCESS:
      let tempUser = { ...state.user };
      if (action.payload?.zone_id !== 0) {
        tempUser.details = { zone_id: action.payload.zone_id };
      }
      return { ...state, user: tempUser, loading: false };
    case LAST_BOOKING_ZONE_ID.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case CAR_REGISTER_CHECK.PENDING:
      return { ...state, errors: null, loading: true };
    case CAR_REGISTER_CHECK.SUCCESS:
      return {
        ...state,
        vehicleCheckDetails: action.payload.data,
        loading: false,
      };
    case CAR_REGISTER_CHECK.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case CAR_REGISTER_CHECK_NULL:
      return { ...state, vehicleCheckDetails: null };

    case FETCH_DRIVER.PENDING:
      return { ...state, errors: null, loading: true };
    case FETCH_DRIVER.SUCCESS:
      return { ...state, driverDetails: action.payload.data, loading: false };
    case FETCH_DRIVER.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case EMERGENCY_DETAILS.PENDING:
      return { ...state, errors: null, loading: true };
    case EMERGENCY_DETAILS.SUCCESS:
      return { ...state, emergencyDetails: action.payload.data, loading: false };
    case EMERGENCY_DETAILS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case GET_EMERGENCY_DETAILS.PENDING:
      return { ...state, errors: null, loading: true };
    case GET_EMERGENCY_DETAILS.SUCCESS:
      return { ...state, emergencyDetails: action.payload.data, loading: false };
    case GET_EMERGENCY_DETAILS.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case DELETE_EMERGENCY_DETAILS.PENDING:
      return { ...state, errors: null, loading: true };
    case DELETE_EMERGENCY_DETAILS.SUCCESS:
      return { ...state, removeDetails: action.payload.data, loading: false };
    case DELETE_EMERGENCY_DETAILS.FAILURE:
      return { ...state, errors: action.payload, loading: false };


    case DRIVER_FILE.PENDING:
      return { ...state, errors: null, loading: true };
    case DRIVER_FILE.SUCCESS:
      const driverDet: any = { ...state.driverDetails };
      driverDet[action.payload.field] = action.payload.file;
      return { ...state, driverDetails: driverDet, loading: false };
    case DRIVER_FILE.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case DRIVER_SAVE.PENDING:
      return { ...state, errors: null, loading: true };
    case DRIVER_SAVE.SUCCESS:
      return { ...state, driverDetails: action.payload.data, loading: false };
    case DRIVER_SAVE.FAILURE:
      return { ...state, errors: action.payload, loading: false };

      
    case DRIVER_SENT_APPROVAL.PENDING:
      return { ...state, errors: null, loading: true };
    case DRIVER_SENT_APPROVAL.SUCCESS:
      return { ...state, sentRequest: action.payload.data, loading: false };
    case DRIVER_SENT_APPROVAL.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case PROFILE_SAVE.PENDING:
      return { ...state, errors: null, loading: true };
    case PROFILE_SAVE.SUCCESS:
      return { ...state, loading: false };
    case PROFILE_SAVE.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case LOGOUT.PENDING:
      return { ...state, errors: null, loading: true };
    case LOGOUT.SUCCESS:
      clearSessionData();
      return { ...state, user: null, loggedIn: false, loading: false };
    case LOGOUT.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    default:
      return state;
  }
};

export default reducer;
