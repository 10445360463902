import React, {useEffect} from 'react';
// import {io} from './socket.lib';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {io} from 'socket.io-client';
import * as booking from 'App/store/actions/booking.action';
import * as auth from 'App/store/actions/auth.action';

//const io = require('App/common/socket.lib');
//const socket = io(process.env.REACT_APP_SOC_API_URL);


const Socket: React.FC<any> = ({
  user,
  zone,
  bookingId,
  currentLocation,
  chatMessageSend,
  fetchChatMessage,
  newCurrentRide,
  bookingDataUpdate,
  rider,
  driver,
  globalData,
}: {
  user: any;
  zone: any;
  bookingId: number;
  currentLocation: any;
  chatMessageSend: any;
  fetchChatMessage: Function;
  newCurrentRide: Function;
  bookingDataUpdate: Function;
  rider: any;
  driver: any;
  globalData: Function;
}) => {
  const socket = io(process.env.REACT_APP_SOC_API_URL ? process.env.REACT_APP_SOC_API_URL : '', {
    transports: ['websocket'], // using WebSocket as the transport
    autoConnect: true,
    reconnection: true, // Enable automatic reconnection
    reconnectionAttempts: Infinity, // Unlimited reconnection attempts
    reconnectionDelay: 1000, // Start with 1 second delay
    reconnectionDelayMax: 5000, // Max out at 5 seconds
    randomizationFactor: 0.5,
  });

  useEffect(() => {
    socket.on('connect', () => {
      const {engine} = socket.io;
      // console.log(engine.transport.name); // in most cases, prints "polling"
      engine.once('upgrade', () => {
        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        // console.log(engine.transport.name); // in most cases, prints "websocket"
      });
    });

    socket.on('initEvent', (data: any) => {
      // console.log('Socket haaaaaa', data);
      globalData(data);
    });

    socket.on('bookingRider', (data: any) => {
      console.log('Socket Rider', data);
      bookingDataUpdate(data.action);
    });

    socket.on('bookingDriver', (data: any) => {
      console.log('Socket Driver', data);
      newCurrentRide(data.action);
    });

    socket.on('bookingMessage', (data: any) => {
      fetchChatMessage(data.action);
    });

    socket.on('bookingMessageDriverLoc', (data: any) => {
      console.log('Socket booking driver location', data);
      //setBookingDriverLocation(data);
    });

    socket.on('disconnect', () => {
      console.log('-----------------------------------------------------');
    });
  }, []);

  /** THis is basic function required */
  useEffect(() => {
    if (user && user.id) {
      socket.emit('registerCustomer', {
        id: user.id,
        type: user.type,
      });
    }
  }, [user]);

  /*useEffect(() => {
    if (user && user.id) {
      console.log({id: user.id, type: user.type, zone_id: 1, de: user.details});
      if (user.type === 'driver' && user.details && user.details.zone_id) {
        socket.emit('licensedtaxi', {
          id: user.id,
          type: user.type,
          zone_id: user.details.zone_id,
        });
      } else if (user.type === 'rider' && zone && zone.zone_id) {
        socket.emit('licensedtaxi', {
          id: user.id,
          type: user.type,
          zone_id: zone.zone_id,
        });
      }
    }
  }, [user, zone]);

  /* Booking Related socket room and send, receive message 
  useEffect(() => {
    if (bookingId && user && user.id) {
      console.log('Socket Joining the Booking Room', bookingId);
      socket.emit('bookingRoom', {booking_id: bookingId, id: user.id});
    }
  }, [bookingId, user]);*/

  useEffect(() => {
    if (chatMessageSend) {
      let toId = rider.id;
      if (user.type === 'rider') {
        toId = driver.id;
      }
      socket.emit('bookingMessage', {
        booking_id: bookingId,
        id: user.id,
        to_id: toId,
        name: user.name,
        msg: chatMessageSend,
      });
    }
    // console.log('chatmessagesend', chatMessageSend);
  }, [chatMessageSend, user]);
  /* Booking Related socket room end */

  useEffect(() => {
    if (currentLocation) {
      if (user && user?.id) {
        const obj:any = {
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
          type: user.type,
          custId: user.id,
        };
        if (bookingId && user.type === 'driver') {
          obj.booking_id = bookingId;
        }
        socket.emit('currentLocation', obj);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, bookingId]);
  return <></>;
};

const mapStateToProps = (state: any) => ({
  message: state.toast.message,
  user: state.user.user,
  zone: state.booking.zone,
  rider: state.booking.rider,
  driver: state.booking.driver,
  bookingId: state.booking.bookingId,
  currentLocation: state.user.currentLocation,
  chatMessageSend: state.booking.chatMessageSend,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  fetchChatMessage: (data: Dispatch<any>) => dispatch(booking.fetchChatMessage(data)),
  newCurrentRide: (data: Dispatch<any>) => dispatch(booking.newCurrentRide(data)),
  bookingDataUpdate: (data: Dispatch<any>) => dispatch(booking.bookingDataUpdate(data)),
  globalData: (data: Dispatch<any>) => dispatch(auth.globalData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Socket);
