import React, { useEffect } from "react";
import { notification } from "antd";
import { connect } from "react-redux";

const ToastMessage: React.FC<any> = ({ message }: { message: any }) => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (message?.type) {
      /*Toast.show({
        visibilityTime: 5000,
        type: message.type,
        text1: message.head,
        text2: message.text,
      });*/
      openNotification();
    }
  }, [message]);

  const openNotification = () => {
    api.open({
      message: message.head,
      description: message.text,
    });
  };

  return <>{contextHolder}</>;
};

const mapStateToProps = (state: any) => ({
  message: state.toast.message,
});

export default connect(mapStateToProps, null)(ToastMessage);
