import { TRANSACTIONS_LIST, PAYMENT_LIST, WITHDRAW_PENDING_LIST, WITHDRAW_UPDATE } from 'App/store/constants';
const initialState = {
  transactions: null,
  withdrawList: null,
  paymentList: null,
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TRANSACTIONS_LIST.PENDING:
      return { ...state, loading: true };
    case TRANSACTIONS_LIST.SUCCESS:
      return { ...state, loading: false, transactions: action.payload.data };
    case TRANSACTIONS_LIST.FAILURE:
      return {
        ...state,
        transactions: null,
        loading: false,
        errors: action.payload,
      };

    case WITHDRAW_PENDING_LIST.PENDING:
      return { ...state, loading: true };
    case WITHDRAW_PENDING_LIST.SUCCESS:
      return { ...state, loading: false, withdrawList: action.payload.data };
    case WITHDRAW_PENDING_LIST.FAILURE:
      return {
        ...state,
        withdrawList: null,
        loading: false,
        errors: action.payload,
      };

    case PAYMENT_LIST.PENDING:
      return { ...state, loading: true };
    case PAYMENT_LIST.SUCCESS:
      return { ...state, loading: false, paymentList: action.payload.data };
    case PAYMENT_LIST.FAILURE:
      return {
        ...state,
        paymentList: null,
        loading: false,
        errors: action.payload,
      };

    case WITHDRAW_UPDATE.PENDING:
      return { ...state, loading: true };
    case WITHDRAW_UPDATE.SUCCESS:
      return { ...state, loading: false };
    case WITHDRAW_UPDATE.FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
