import { SCHEDULE_LIST, SCHEDULE_DETAILS,SCHEDULE_CANCEL, SCHEDULE_PO_LIST } from 'App/store/constants';
const initialState = {
  list: [],
  poList: [],
  scheduleDetails: {},
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SCHEDULE_LIST.PENDING:
      return { ...state, list: [], loading: true };
    case SCHEDULE_LIST.SUCCESS:
      return { ...state, list: action.payload?.data || [], loading: false };
    case SCHEDULE_LIST.FAILURE:
      return { ...state, list: [], errors: action.payload };
      case SCHEDULE_PO_LIST.PENDING:
      return { ...state, loading: true };
    case SCHEDULE_PO_LIST.SUCCESS:
      return { ...state, loading: false, poList: action.payload.data };
    case SCHEDULE_PO_LIST.FAILURE:
    case SCHEDULE_DETAILS.PENDING:
      return { ...state, loading: true };
    case SCHEDULE_DETAILS.SUCCESS:
      return { ...state, loading: false, scheduleDetails: action.payload.data };
    case SCHEDULE_DETAILS.FAILURE:
    case SCHEDULE_CANCEL.PENDING:
      return { ...state, loading: true };
    case SCHEDULE_CANCEL.SUCCESS:
      return { ...state, scheduleDetails: action.payload.data || null, loading: false };
    case SCHEDULE_CANCEL.FAILURE:
      return {
        ...state,
        poList: null,
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
