import {ADDRESS_LIST} from 'App/store/constants';
const initialState = {
  list: null,
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADDRESS_LIST.PENDING:
      return {...state, list: null, loading: true};
    case ADDRESS_LIST.SUCCESS:
      return {...state, list: action.payload.data};
    case ADDRESS_LIST.FAILURE:
      return {...state, list: null, errors: action.payload};
    default:
      return state;
  }
};

export default reducer;
