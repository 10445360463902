import React from "react";
import { Provider } from "react-redux";
import store from "App/store/store";
import AppNavigator from "App/common/AppNavigator";
import { ConfigProvider} from "antd";

const App: React.FC = () => {
  return (
    <div className="app">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: "#CC0000",
                algorithm: true, // Enable algorithm
              },
              Input: {
                colorPrimary: "#CC0000",
                algorithm: true, // Enable algorithm
              },
            },
          }}
        >
          <AppNavigator />
        </ConfigProvider>
      </Provider>
    </div>
  );
};

export default App;
