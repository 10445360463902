import axios from "axios";
import store from "App/store/store";
import { TOAST_MESSAGE } from "App/store/constants";

const urlList: any = {
  login: process.env.REACT_APP_LOGIN_API_URL,
  taxi: process.env.REACT_APP_TAXI_API_URL,
  payment: process.env.REACT_APP_PAY_API_URL,
};

export const MakeApiRequest = ({
  server,
  url,
  method = "GET",
  data,
  params,
  headers,
  successMsg = false,
  errorMsg = false,
  nextActionType,
}: {
  server: string;
  url: string;
  method?: string;
  data?: any;
  params?: any;
  headers?: any;
  successMsg?: boolean;
  errorMsg?: boolean;
  nextActionType?: any;
}) => {
  // return (dispatch: Dispatch) => {
    let baseURL = urlList[server];
    const storeData:any = store.getState();

    let token = "";
    if (storeData && storeData.user && storeData.user?.token) {
      token = "Bearer " + storeData.user.token;
    }
    const headersData: any = {
      "Content-type": "application/json",
      Authorization: token,
    };

    if (headers) {
      Object.entries(headers).forEach((item: any) => {
        headersData[item[0]] = item[1];
      });
    }
    if (headersData["Content-type"] === "application/json") {
      data = JSON.stringify(data);
    }

    const options = {
      baseURL: baseURL,
      method: method.trim().toLowerCase().toUpperCase(),
      url: url.trim(),
      data,
      headers: headersData,
      params: params,
      withCredentials: true,
    };

    store.dispatch({ type: nextActionType.PENDING, payload: "" });
    axios(options)
      .then((res: any) => {
        if (successMsg && res?.data?.msg) {
          store.dispatch({
            type: TOAST_MESSAGE,
            payload: { type: "success", head: "Success", text: res.data.msg },
          });
        }
        store.dispatch({ type: nextActionType.SUCCESS, payload: res.data });
      })
      .catch((err) => {
        try {
          let res = err.response.data;
          if ((typeof res).toString() === "string") {
            res = JSON.parse(err.response.data);
          }
          if(errorMsg) {
            store.dispatch({
              type: TOAST_MESSAGE,
              payload: { type: "error", head: "Error", text: res.msg },
            });
          }
        } catch (error) {
          console.log(error);
          console.log("Error in response");
        }
        store.dispatch({
          type: nextActionType.FAILURE,
          payload: err?.response?.data ? err.response.data : '',
          status: err?.response?.status ? err.response.status: '',
        });
      });
  // };
};
