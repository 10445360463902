import React from "react";
import { Spin } from "antd";

const Loader: React.FC<any> = ({ message }: { message: string }) => {
  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', gap: 4}}>
      <Spin />
      {message && <div>{message}</div>}
    </div>
  );
};

export default Loader;
