import {TOAST_MESSAGE} from 'App/store/constants';

const initialState = {
  message: {},
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOAST_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
