import {
  LOGIN,
  LOGIN_DETAILS,
  FETCH_OTP,
  REFRESH_TOKEN,
  REGISTER_CHECK,
  RESEND_OTP,
  LOGIN_VERIFY_STEP,
  REGISTER_VERIFY_STEP,
  CLEAR_LOGIN_DATA,
  GLOBAL_DATA,
} from 'App/store/constants';
import {MakeApiRequest} from 'App/common/Http';

export const loginSubmit = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'cust/login-otp',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: true,
    nextActionType: FETCH_OTP,
  });
};

export const loginSubmitWithOTP = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'cust/login-success',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: true,
    nextActionType: LOGIN,
  });
};

export const resendOTP = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'cust/resend-otp',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: true,
    nextActionType: RESEND_OTP,
  });
};

export const loginDetailsSave = (data: any) => (
  (dispatch: any) => (dispatch({
  type: LOGIN_DETAILS,
  payload: data,
  }))
);

export const clearData = () => ({
  type: CLEAR_LOGIN_DATA,
});

export const globalData = (data: any) => ({
  type: GLOBAL_DATA,
  payload: data,
});

export const resetLoginVerifyStep = () => ({
  type: LOGIN_VERIFY_STEP,
});

export const resetRegisterVerifyStep = () => ({
  type: REGISTER_VERIFY_STEP,
});

export const registerSubmit = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'cust/register',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: true,
    nextActionType: REGISTER_CHECK,
  });
};

export const registerSubmitWithOTP = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'cust/register-otp',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: true,
    nextActionType: LOGIN,
  });
};

export const refreshToken = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'cust/mobile-refresh-token',
    method: 'POST',
    data: data,
    nextActionType: REFRESH_TOKEN,
  });
};
