import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as auth from "App/store/actions/auth.action";
import Loader from "App/utils/Loader";
import { useNavigate } from "react-router-dom";
import "./css/RefreshToken.css";

const SplashScreen: React.FC<any> = ({ refreshToken, loggedIn, loading }) => {
  //const location = useNavigate();

  useEffect(() => {
    let token: any = localStorage.getItem("refreshToken");
    refreshToken({ token: token });
  }, []);

  useEffect(() => {
    if (!loading && loggedIn) {
      // navigate("/app");
    }
  }, [loggedIn, loading]);

  return (
    <>
      {loading && (
        <div className="refreshContainer">
          <div className="refreshSubContainer">
            <div className="refreshElement">
              <Loader message="Loading..." />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loggedIn: state.user.loggedIn,
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  refreshToken: (data: any) => auth.refreshToken(data),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
