import {ACTIVITY_LIST, ACTIVITY_DETAILS} from 'App/store/constants';
const initialState = {
  activityListData: null,
  activityDetailData: null,
  errors: null,
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIVITY_LIST.PENDING:
      return {...state, errors: null, activityListData: null, loading: true};
    case ACTIVITY_LIST.SUCCESS:
      return {...state, activityListData: action.payload.data, loading: false};
    case ACTIVITY_LIST.FAILURE:
      return {...state, errors: action.payload, loading: false};

    case ACTIVITY_DETAILS.PENDING:
      return {
        ...state,
        errors: null,
        activityDetailData: action.payload.data,
        loading: true,
      };
    case ACTIVITY_DETAILS.SUCCESS:
      return {
        ...state,
        activityDetailData: action.payload.data,
        loading: false,
      };
    case ACTIVITY_DETAILS.FAILURE:
      return {...state, errors: action.payload, loading: false};
    default:
      return state;
  }
};

export default reducer;
