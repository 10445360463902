import {BREAD_CRUMB} from 'App/store/constants';

const initialState = {
  breadCrumb: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BREAD_CRUMB:
      return {
        ...state,
        breadCrumb: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
