import {
  PICKUP,
  DROP,
  BOOKING_DISTANCE,
  BOOKING_DURATION,
  CAR_TYPE,
  SELECTED_LOCATION,
  ESTIMATED_PRICES,
  AVAILABLE_CARS,
  BOOKING_SAVE,
  BOOKING_CANCEL,
  BOOKING_SCREEN_SIZE,
  BOOKING_DRIVER,
  CHECK_ZONE,
  CURRENT_RIDE_CHECK,
  CANCEL_REASONS,
  START_TRIP,
  STOP_TRIP,
  CHAT_MESSAGE_SEND,
  CHAT_MESSAGE_UPDATE,
  SELECT_RIDE,
  NEW_CURRENT_RIDE,
  CLEAR_BOOKING,
  PAYMENT_CONFIRM_TRIP,
  CAR_EST_AMOUNT,
  BOOKING_DATA_UPDATE,
  RATING_SAVE,
  LATEST_RIDES,
} from 'App/store/constants';
import {MakeApiRequest} from 'App/common/Http';

export const checkCurrentRide = () => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'bookings/list/current/ride',
    method: 'GET',
    nextActionType: CURRENT_RIDE_CHECK,
  });
};

export const loadLatestRides = () => {
  return MakeApiRequest({
    server: 'taxi',
    url: `bookings/latest-rides`,
    method: 'GET',
    nextActionType: LATEST_RIDES,
  });
};

/*export const checkPickupZone = (data: any) => ({
  type: API_CALL,
  payload: {
    server: 'taxi',
    url: 'bookings/checkzone',
    method: 'POST',
    data: data,
    nextActionType: CHECK_ZONE,
  },
});*/


export const checkPickupZone = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'bookings/checkzone',
    method: 'POST',
    data: data,
    nextActionType: CHECK_ZONE,
  });
};

export const fetchAvailableCars = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'location/nearest-drivers',
    method: 'POST',
    data: data,
    nextActionType: AVAILABLE_CARS,
  });
};

export const fetchEstimatedPrices = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'bookings/estimate_price',
    method: 'POST',
    data: data,
    nextActionType: ESTIMATED_PRICES,
  });
};

export const saveBooking = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'bookings/save',
    method: 'POST',
    data: data,
    nextActionType: BOOKING_SAVE,
  });
};

export const fetchCancelReasons = (zoneId: number) => {
  return MakeApiRequest({
    server: 'taxi',
    url: `cancelreason/list/${zoneId}`,
    method: 'GET',
    nextActionType: CANCEL_REASONS,
  });
};

export const cancelBooking = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'bookings/cancel',
    method: 'POST',
    data: data,
    nextActionType: BOOKING_CANCEL,
  });
};

export const startTripDriver = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'driverbookings/start-trip',
    method: 'POST',
    data: data,
    nextActionType: START_TRIP,
  });
};

export const stopTripDriver = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'driverbookings/stop-trip',
    method: 'POST',
    data: data,
    nextActionType: STOP_TRIP,
  });
};

export const paymentConfirmDriver = (data: any) => {
  return MakeApiRequest({
    server: 'taxi',
    url: 'driverbookings/payment-trip',
    method: 'POST',
    data: data,
    nextActionType: PAYMENT_CONFIRM_TRIP,
  });
};

export const saveRating = (data: any) => {
  return MakeApiRequest({
    server: 'login',
    url: 'rating',
    method: 'POST',
    data: data,
    successMsg: true,
    errorMsg: true,
    nextActionType: RATING_SAVE,
  });
};

export const bookingDataUpdate = (data: any) => ({
  type: BOOKING_DATA_UPDATE,
  payload: data,
});

export const newCurrentRide = (data: any) => ({
  type: NEW_CURRENT_RIDE,
  payload: data,
});

export const clearBooking = () => ({
  type: CLEAR_BOOKING,
});

export const selectRide = (bookingId: number) => ({
  type: SELECT_RIDE,
  payload: bookingId,
});

export const saveChatMessage = (data: any) => ({
  type: CHAT_MESSAGE_SEND,
  payload: data,
});

export const fetchChatMessage = (data: any) => ({
  type: CHAT_MESSAGE_UPDATE,
  payload: data,
});

export const savePickup = (data: any) => ({
  type: PICKUP,
  payload: data,
});

export const saveDrop = (data: any) => ({
  type: DROP,
  payload: data,
});

export const saveDuration = (data: any) => ({
  type: BOOKING_DURATION,
  payload: data,
});

export const saveDistance = (data: any) => ({
  type: BOOKING_DISTANCE,
  payload: data,
});

export const saveCarType = (data: number) => ({
  type: CAR_TYPE,
  payload: data,
});

export const saveCarEstAmount = (data: number) => ({
  type: CAR_EST_AMOUNT,
  payload: data,
})

export const updateDriver = (data: any) => ({
  type: BOOKING_DRIVER,
  payload: data,
});

export const updateBookingScreenSize = (data: any) => ({
  type: BOOKING_SCREEN_SIZE,
  payload: data,
});

export const updateSelectedLocation = (data: any) => ({
  type: SELECTED_LOCATION,
  payload: data,
});
