import { FAQ_LIST } from 'App/store/constants';
const initialState = {
  faqs: [],
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FAQ_LIST.PENDING:
      return { ...state, faqs: [], loading: true };
    case FAQ_LIST.SUCCESS:
      return { ...state, faqs: action.payload.data.data, loading: false };
    case FAQ_LIST.FAILURE:
      return { ...state, faqs: [], errors: action.payload, loading: false };
    default:
      return state;
  }
};

export default reducer;
