import React from 'react';
import { Modal } from 'antd';

// Define TypeScript interface for props
interface MaintenanceProps {
  title: string;
  isVisible: boolean;
}

// Convert to a functional component with TypeScript
const Maintenance: React.FC<MaintenanceProps> = ({ title, isVisible }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Modal
        title="Maintenance"
        visible={isVisible}
        onCancel={() => {}}
        footer={null} // Use `null` to remove the footer
        maskClosable={false}
      >
        <div style={{ paddingTop: 20 }}>
          <p style={{ textAlign: 'center' }}>{title}</p>
        </div>
      </Modal>
    </div>
  );
};

export default Maintenance;
