import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RefreshToken from "./RefreshToken";
import { connect } from "react-redux";
import Loader from "App/utils/Loader";
import Socket from "App/common/Socket";
import ToastMessage from "App/common/ToastMessage";
import Maintaince from "App/common/Maintaince";
const AuthLayout = lazy(() => import("App/common/AuthLayout"));
const AppLayout = lazy(() => import("App/common/AppLayout"));
const PaymentRoutes = lazy(() => import("App/common/PaymentRoutes"));
// const StaticPage = lazy(() => import("App/screens/pages/StaticPage"));

const AppNavigator = ({
  refreshTokenLoaded,
  globalData,
}: {
  refreshTokenLoaded: boolean;
  globalData: any;
}) => {
  return (
    <>
      <ToastMessage />
      <Socket />
      <RefreshToken />
      {globalData && globalData.maintainceMode && (
        <Maintaince isVisible={true} title={globalData.maintainceObj.title} />
      )}
      {refreshTokenLoaded ? (
        <Suspense fallback={<Loader message="Loading..." />}>
          <BrowserRouter>
            <Routes>
              <Route path="/payment/*" Component={PaymentRoutes} />
              <Route path="/*" Component={AuthLayout} />
              <Route path="/app/*" Component={AppLayout} />
            </Routes>
          </BrowserRouter>
          {/* <HashRouter basename="#">
          <Routes>
            <Route path="/" Component={StaticPage}/> {/* 👈 Renders at /#/app/ /}
          </Routes>
        </HashRouter> */}
        </Suspense>
      ) : (<Loader message="Loading..." />)}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  refreshTokenLoaded: state.user.refreshTokenLoaded,
  globalData: state.auth.globalData,
});

export default connect(mapStateToProps, null)(AppNavigator);
