import { legacy_createStore as createStore, applyMiddleware, Middleware, Dispatch, AnyAction } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer, { RootState } from './reducers';  // Adjust the import path as necessary

// Create the logger middleware
const logger = createLogger({
  collapsed: true,
  diff: true,
});

// Define the type for the middleware array
const middlewares: Middleware<{}, RootState, Dispatch<AnyAction>>[] = [
  thunk as ThunkMiddleware<RootState, AnyAction>,
  logger as Middleware<{}, RootState, Dispatch<AnyAction>>, // Cast logger to correct type
];

// Create the Redux store
const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);

export default store;
