import {
  PICKUP,
  DROP,
  BOOKING_DISTANCE,
  BOOKING_DURATION,
  CAR_TYPE,
  SELECTED_LOCATION,
  AVAILABLE_CARS,
  BOOKING_SAVE,
  BOOKING_SCREEN_SIZE,
  BOOKING_DRIVER,
  ESTIMATED_PRICES,
  CHECK_ZONE,
  CURRENT_RIDE_CHECK,
  CANCEL_REASONS,
  BOOKING_CANCEL,
  START_TRIP,
  STOP_TRIP,
  PAYMENT_CONFIRM_TRIP,
  CHAT_MESSAGE_SEND,
  CHAT_MESSAGE_UPDATE,
  SELECT_RIDE,
  NEW_CURRENT_RIDE,
  CLEAR_BOOKING,
  BOOKING_DATA_UPDATE,
  RATING_SAVE,
  LATEST_RIDES,
  CAR_EST_AMOUNT,
} from "App/store/constants";
const initialState = {
  currentRidesList: null as any,
  bookingId: null,
  pickup: null,
  drop: [],
  distance: null,
  duration: null,
  carType: null,
  driver: null as any,
  rider: null as any,
  otp: null,
  chat: null,
  chatMessageSend: null,
  bookingSchedule: null,
  bookingTime: null,
  bookingStatus: null,
  selectedLocation: null as any,
  bookingScreenSize: [1, 200],
  estimatedTime: null,
  estimatedDistance: null,
  estimatedPrices: null,
  availableCars: null,
  cancelReasons: null,
  tripStartTime: null,
  tripTime: null,
  tripDistance: null,
  tripAmount: null,
  zone: null,
  zoneAvailable: null,
  zoneUnAvailableMsg: null,
  errors: {},
  loading: false,
  latestrides: null,
  carEstAmount: 0,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PICKUP:
      return { ...state, pickup: action.payload };
    case DROP:
      let tempDrop: any = [...state.drop];
      if (tempDrop) {
        if (state.selectedLocation?.stop !== undefined) {
          tempDrop[state.selectedLocation.stop] = action.payload;
        } else {
          tempDrop[0] = action.payload;
        }
      }
      return { ...state, drop: tempDrop };
    case SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case BOOKING_DISTANCE:
      return { ...state, distance: action.payload };
    case BOOKING_DURATION:
      return { ...state, duration: action.payload };
    case CAR_TYPE:
      return { ...state, carType: action.payload };
    case CAR_EST_AMOUNT:
      return { ...state, carEstAmount: action.payload };
    case BOOKING_SCREEN_SIZE:
      return { ...state, bookingScreenSize: action.payload };
    case BOOKING_DRIVER:
      return { ...state, driver: action.payload };
    case CHAT_MESSAGE_SEND:
      return { ...state, chatMessageSend: action.payload };
    case CHAT_MESSAGE_UPDATE:
      let tempChat: any = [];
      if (state.chat) {
        tempChat = [...state?.chat];
      }
      tempChat.push(action.payload);
      return { ...state, chat: tempChat };

    case CURRENT_RIDE_CHECK.PENDING:
      return { ...state, loading: true };
    case CURRENT_RIDE_CHECK.SUCCESS:
      let totalRides = [];
      if (action.payload.data.length > 0) {
        totalRides = action.payload.data.map((item: any) => {
          return {
            ...item,
            pickup: JSON.parse(item.pickup),
            drop: JSON.parse(item.drop),
            bookingTime: item.booking_time,
            bookingStatus: item.status,
          };
        });
      }
      return {
        ...state,
        loading: false,
        currentRidesList: totalRides,
      };
    case CURRENT_RIDE_CHECK.FAILURE:
      return {
        ...state,
        loading: false,
        currentRidesList: null,
        errors: action.payload,
      };
    case NEW_CURRENT_RIDE:
      let currentRides: any = [];
      if (state.currentRidesList) {
        currentRides = [...state.currentRidesList];
      }
      currentRides.unshift(action.payload);
      return { ...state, currentRidesList: currentRides };

    case BOOKING_DATA_UPDATE:
      let currentData = { ...state };
      let temp = action.payload;
      if (temp.booking_id === currentData.bookingId) {
        currentData.driver = {
          id: temp.driver_id,
          name: temp.driver_name,
          details: temp.driver_details,
        };
        currentData.bookingStatus = temp.status;
        currentData.rider = {
          id: temp.rider_id,
          name: temp.rider_name,
          details: temp.rider_details,
        };
        currentData.otp = temp.otp;
        currentData.bookingId = temp.booking_id;
        currentData.tripStartTime = temp.trip_started_time;
        currentData.tripTime = temp.trip_time;
        currentData.tripDistance = temp.trip_distance;
        currentData.tripAmount = temp.trip_amount;
        currentData.bookingSchedule = temp.booking_schedule;
        currentData.bookingTime = temp.booking_time;
        currentData.duration = temp.duration;
        currentData.estimatedTime = temp.estimated_time;
        currentData.estimatedDistance = temp.estimated_distance;

        let deletedItem = null;
        currentData.currentRidesList.forEach((item: any, index: number) => {
          if (item.id === currentData.bookingId) {
            currentData.currentRidesList[index].status = temp.status;
            if (
              (temp.status === "completed" || temp.status === "cancelled",
                temp.status === "deleted" || temp.status === "inactive")
            ) {
              deletedItem = index;
            }
          }
        });
        if (deletedItem) {
          currentData.currentRidesList.splice(deletedItem, 1);
        }
      }
      return { ...currentData };
    case SELECT_RIDE:
      let rideData = state.currentRidesList[0];
      let chat = null;
      if (rideData?.chat_queue) {
        if (typeof rideData.chat_queue === "string") {
          chat = JSON.parse(rideData.chat_queue);
        }
      }
      let rider = null;
      let driver = null;

      if (rideData.rider_id && rideData.rider_id !== "") {
        let details = {};
        if (typeof rideData.rider_details === "string") {
          details = JSON.parse(rideData.rider_details);
        }
        rider = {
          id: rideData.rider_id,
          name: rideData.rider_name,
          details: details,
        };
      }

      if (rideData.driver_id && rideData.driver_id !== "") {
        let details = {};
        if (typeof rideData.driver_details === "string") {
          details = JSON.parse(rideData.driver_details);
        }
        driver = {
          id: rideData.driver_id,
          name: rideData.driver_name,
          details: details,
        };
      }
      let deletedItem = null;
      let currentRidesList: any = null;

      if (state.currentRidesList) {
        currentRidesList = [...state.currentRidesList];
        currentRidesList.forEach((item: any, index: number) => {
          if (item.id === state.bookingId) {
            currentRidesList[index].status = rideData.status;
            if (
              (rideData.status === "completed" ||
                rideData.status === "cancelled",
                rideData.status === "deleted" || rideData.status === "inactive")
            ) {
              deletedItem = index;
            }
          }
        });
        if (deletedItem) {
          currentRidesList.splice(deletedItem, 1);
        }
      }

      return {
        ...state,
        currentRidesList: currentRidesList,
        pickup: {
          location: {
            latitude: rideData.pickup.lat,
            longitude: rideData.pickup.lon,
          },
          address: rideData.pickup.add,
        },
        drop: rideData.drop.map((item: any) => ({
          location: {
            latitude: item.lat,
            longitude: item.lon,
          },
          address: item.add,
        })),
        zone: rideData.pickup.zone,
        carType: rideData.car_type_id,
        distance: rideData.estimated_distance,
        duration: rideData.estimated_time,
        estimated_amount: rideData.estimated_amount,
        bookingId: rideData.id,
        otp: rideData.otp,
        driver: driver,
        rider: rider,
        chat: chat,
        tripStartTime: rideData.trip_started_time,
        tripTime: rideData.trip_time,
        tripDistance: rideData.trip_distance,
        tripAmount: rideData.trip_amount,
        bookingSchedule: rideData.booking_schedule,
        bookingTime: rideData.booking_time,
        bookingStatus: rideData.status,
      };

    case CHECK_ZONE.PENDING:
      return { ...state, zone: null, loading: true };
    case CHECK_ZONE.SUCCESS:
      return {
        ...state,
        loading: false,
        zone: action.payload.data,
        zoneAvailable: action.payload.zone_available,
        zoneUnAvailableMsg: action.payload.msg,
      };
    case CHECK_ZONE.FAILURE:
      return { ...state, zone: null, loading: false, errors: action.payload };

    case AVAILABLE_CARS.PENDING:
      return { ...state, availableCars: null, loading: true };
    case AVAILABLE_CARS.SUCCESS:
      return { ...state, availableCars: action.payload.data, loading: false };
    case AVAILABLE_CARS.FAILURE:
      return {
        ...state,
        availableCars: null,
        errors: action.payload,
        loading: false,
      };

    case ESTIMATED_PRICES.PENDING:
      return { ...state, estimatedPrices: null, loading: true };
    case ESTIMATED_PRICES.SUCCESS:
      return { ...state, estimatedPrices: action.payload.data, loading: false };
    case ESTIMATED_PRICES.FAILURE:
      return {
        ...state,
        estimatedPrices: null,
        errors: action.payload,
        loading: false,
      };

    case BOOKING_SAVE.PENDING:
      return { ...state, bookingId: null, loading: true };
    case BOOKING_SAVE.SUCCESS:
      return {
        ...state,
        bookingId: action.payload.data.booking_id,
        bookingStatus: "pending",
        loading: false,
      };
    case BOOKING_SAVE.FAILURE:
      return {
        ...state,
        bookingId: null,
        errors: action.payload,
        loading: false,
      };

    case START_TRIP.PENDING:
      return { ...state, loading: true };
    case START_TRIP.SUCCESS:
      return {
        ...state,
        bookingStatus: action.payload.status,
        tripStartTime: action.payload.trip_started_time,
        loading: false,
      };
    case START_TRIP.FAILURE:
      return { ...state, loading: false, errors: action.payload };

    case STOP_TRIP.PENDING:
      return { ...state, loading: true };
    case STOP_TRIP.SUCCESS:
      return {
        ...state,
        bookingStatus: action.payload.status,
        tripTime: action.payload.trip_time,
        tripDistance: action.payload.trip_distance,
        tripAmount: action.payload.trip_amount,
        loading: false,
      };
    case STOP_TRIP.FAILURE:
      return { ...state, loading: false, errors: action.payload };

    case PAYMENT_CONFIRM_TRIP.PENDING:
      return { ...state, loading: true };
    case PAYMENT_CONFIRM_TRIP.SUCCESS:
      return {
        ...state,
        bookingStatus: action.payload.status,
        payment: action.payload.payment,
        loading: false,
      };
    case PAYMENT_CONFIRM_TRIP.FAILURE:
      return { ...state, loading: false, errors: action.payload };

    case CANCEL_REASONS.PENDING:
      return { ...state, cancelReasons: null, loading: true };
    case CANCEL_REASONS.SUCCESS:
      return { ...state, cancelReasons: action.payload.data, loading: false };
    case CANCEL_REASONS.FAILURE:
      return {
        ...state,
        cancelReasons: null,
        errors: action.payload,
        loading: false,
      };

    case LATEST_RIDES.PENDING:
      return { ...state, latestrides: null, loading: true };
    case LATEST_RIDES.SUCCESS:
      return { ...state, latestrides: action.payload.data, loading: false };
    case LATEST_RIDES.FAILURE:
      return {
        ...state,
        latestrides:[],
        errors: action.payload,
        loading: false,
      };

    case BOOKING_CANCEL.PENDING:
      return { ...state, bookingId: null, loading: true };
    case BOOKING_CANCEL.SUCCESS:
      return {
        ...state,
        loading: false,
        bookingId: null,
        pickup: null,
        drop: [],
        distance: null,
        duration: null,
        carType: null,
        driver: null,
        rider: null,
        otp: null,
        chat: null,
        chatMessageSend: null,
        bookingSchedule: null,
        bookingTime: null,
        bookingStatus: null,
        selectedLocation: null as any,
        estimatedPrices: null,
        estimatedTime: null,
        estimatedDistance: null,
        availableCars: null,
        cancelReasons: null,
        tripStartTime: null,
        tripTime: null,
        tripDistance: null,
        tripAmount: null,
        zone: null,
        zoneAvailable: null,
        zoneUnAvailableMsg: null,
      };
    case BOOKING_CANCEL.FAILURE:
      return { ...state, errors: action.payload, loading: false };

    case RATING_SAVE.PENDING:
      return { ...state };
    case RATING_SAVE.SUCCESS:
      return { ...state };
    case RATING_SAVE.FAILURE:
      return { ...state, errors: action.payload };

    case CLEAR_BOOKING:
      return {
        ...state,
        loading: false,
        bookingId: null,
        pickup: null,
        drop: [],
        distance: null,
        duration: null,
        carType: null,
        driver: null,
        rider: null,
        otp: null,
        chat: null,
        chatMessageSend: null,
        bookingSchedule: null,
        bookingTime: null,
        bookingStatus: null,
        selectedLocation: null as any,
        estimatedPrices: null,
        estimatedTime: null,
        estimatedDistance: null,
        availableCars: null,
        cancelReasons: null,
        tripStartTime: null,
        tripTime: null,
        tripDistance: null,
        tripAmount: null,
        zone: null,
        zoneAvailable: null,
        zoneUnAvailableMsg: null,
      };
    default:
      return state;
  }
};

export default reducer;
