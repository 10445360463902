import { TICKET_LIST, TICKET_REPLY, CREATE_TICKET, CATEGORY_LIST, QUESTION_LIST } from 'App/store/constants';
const initialState = {
  tickets: [],
  replies: null,
  reply: null,
  selectedTicket: null,
  categories: null,
  questions: null,
  errors: {},
  loading: false,
};


const reducer = (state = initialState, action: any) => {
  if (!action || !action.type) {
    console.error('Invalid action dispatched', action);
    return state;
  }

  switch (action.type) {
    case TICKET_LIST.PENDING:
      return { ...state, loading: true };
    case TICKET_LIST.SUCCESS:
      return { ...state, loading: false, tickets: action.payload.data };
    case TICKET_LIST.FAILURE:
      return {
        ...state,
        tickets: null,
        loading: false,
        errors: action.payload,
      };

    case TICKET_REPLY.PENDING:
      return { ...state, replies: null, loading: true };
    case TICKET_REPLY.SUCCESS:
      return { ...state, loading: false, replies: action.payload.data };
    case TICKET_REPLY.FAILURE:
      return {
        ...state,
        replies: null,
        loading: false,
        errors: action.payload,
      };

    case CREATE_TICKET.PENDING:
      return { ...state, loading: true };
    case CREATE_TICKET.SUCCESS:
      return { ...state, loading: false, tickets: action.payload.data };
    case CREATE_TICKET.FAILURE:
      return {
        ...state,
        tickets: [],
        loading: false,
        errors: action.payload,
      };

    case CATEGORY_LIST.PENDING:
      return { ...state, loading: true };
    case CATEGORY_LIST.SUCCESS:
      return { ...state, loading: false, categories: action.payload.data };
    case CATEGORY_LIST.FAILURE:
      return {
        ...state,
        categories: [],
        loading: false,
        errors: action.payload,
      };

    case QUESTION_LIST.PENDING:
      return { ...state, loading: true };
    case QUESTION_LIST.SUCCESS:
      return { ...state, loading: false, questions: action.payload.data };
    case QUESTION_LIST.FAILURE:
      return {
        ...state,
        questions: [],
        loading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
