import { COUNTRIES, STATIC_PAGE, ZONES, CAR_TYPES } from 'App/store/constants';
const initialState = {
  countries: [],
  zones: [],
  cars: [],
  errors: {},
  loading: false,
  staticPage: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COUNTRIES.PENDING:
      return { ...state, loading: true };
    case COUNTRIES.SUCCESS:
      const countriesList = action?.payload?.data.map((item: any) => ({
        label: item.country_name,
        value: item.mobile_code,
      }));
      return { ...state, loading: false, countries: countriesList };
    case COUNTRIES.FAILURE:
      return { ...state, countries: [], loading: false, errors: action.payload };

    case STATIC_PAGE.PENDING:
      return { ...state, loading: true };
    case STATIC_PAGE.SUCCESS:
      return { ...state, loading: false, staticPage: action.payload.data };
    case STATIC_PAGE.FAILURE:
      return {
        ...state,
        staticPage: null,
        loading: false,
        errors: action.payload,
      };

    case ZONES.PENDING:
      return { ...state, loading: true };
    case ZONES.SUCCESS:
      return { ...state, loading: false, zones: action.payload.data };
    case ZONES.FAILURE:
      return { ...state, loading: false, errors: action.payload };

    case CAR_TYPES.PENDING:
      return { ...state, loading: true };
    case CAR_TYPES.SUCCESS:
      return { ...state, loading: false, cars: action.payload.data };
    case CAR_TYPES.FAILURE:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export default reducer;
