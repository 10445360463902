import { FETCH_SETTINGS, UPDATE_SETTINGS, TIMEZONES } from 'App/store/constants';

const initialState = {
    settings: null,
    timezones: null,
    loading: false,
    errors: null,
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_SETTINGS.PENDING:
            return { ...state, loading: true };
        case FETCH_SETTINGS.SUCCESS:
            console.log('FETCH_SETTINGS.SUCCESS payload:', action.payload);
            return { ...state, loading: false, settings: action.payload.data };
        case FETCH_SETTINGS.FAILURE:
            console.error('FETCH_SETTINGS.FAILURE payload:', action.payload);
            return { ...state, settings: null, loading: false, errors: action.payload };

        case UPDATE_SETTINGS.PENDING:
            return { ...state, loading: true };
        case UPDATE_SETTINGS.SUCCESS:
            console.log('UPDATE_SETTINGS.SUCCESS payload:', action.payload.data);
            return { ...state, loading: false };
        case UPDATE_SETTINGS.FAILURE:
            console.error('UPDATE_SETTINGS.FAILURE payload:', action.payload);
            return { ...state, loading: false, errors: action.payload };

        case TIMEZONES.PENDING:
            return { ...state, loading: true };
        case TIMEZONES.SUCCESS:
            console.log('TIMEZONES.SUCCESS payload:', action.payload);
            return { ...state, loading: false, timezones: action.payload.data };
        case TIMEZONES.FAILURE:
            console.error('TIMEZONES.FAILURE payload:', action.payload);
            return { ...state, timezones: [], loading: false, errors: action.payload };

        default:
            return state;
    }
};

export default reducer;
