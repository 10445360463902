import { DRIVER_DASHBOARD, RIDER_DASHBOARD, BOOKINGS_HISTORY, CURRENT_BALANCE } from "App/store/constants";
const initialState = {
  driverData: null,
  riderData: null,
  bookingHistory: null,
  currentBalance: null,
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DRIVER_DASHBOARD.PENDING:
      return { ...state, loading: true };
    case DRIVER_DASHBOARD.SUCCESS:
      const temp: any = [['Date', 'Completed', 'Cancelled', 'Not Accepted']];
        Object.entries(action.payload.data.rec).forEach((item) => {
          temp.push(item[1]);
        });
        action.payload.data.rec = temp;
        action.payload.data.latest = [];
      return { ...state, loading: false, driverData: action.payload.data };
    case DRIVER_DASHBOARD.FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case RIDER_DASHBOARD.PENDING:
      return { ...state, loading: true };
    case RIDER_DASHBOARD.SUCCESS:
      return { ...state, loading: false, riderData: action.payload.data };
    case RIDER_DASHBOARD.FAILURE:
      return { ...state, loading: false, errors: action.payload };


      case BOOKINGS_HISTORY.PENDING:
        return { ...state, loading: true };
      case BOOKINGS_HISTORY.SUCCESS:
        return { ...state, loading: false, bookingHistory: action.payload.data.data };
      case BOOKINGS_HISTORY.FAILURE:
        return { ...state, loading: false, errors: action.payload };

        case CURRENT_BALANCE.PENDING:
          return { ...state, loading: true };
        case CURRENT_BALANCE.SUCCESS:
          return { ...state, loading: false, currentBalance: action.payload.data };
        case CURRENT_BALANCE.FAILURE:
          return { ...state, loading: false, errors: action.payload };


    default:
      return state;
  }
};

export default reducer;
