export const asyncAction = (type: string) => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const API_CALL = 'API_CALL';
export const FETCH_OTP = asyncAction('FETCH_OTP');
export const LOGIN = asyncAction('LOGIN');
export const LOGOUT = asyncAction('LOGOUT');
export const LOGIN_DETAILS = 'LOGIN_DETAILS';
export const LOGIN_VERIFY_STEP = 'LOGIN_VERIFY_STEP';

export const REGISTER_CHECK = asyncAction('REGISTER_CHECK');
export const REGISTER = asyncAction('REGISTER');
export const REFRESH_TOKEN = asyncAction('REFRESH_TOKEN');
export const REGISTER_VERIFY_STEP = 'REGISTER_VERIFY_STEP';

export const RESEND_OTP = asyncAction('RESEND_OTP');
export const CLEAR_LOGIN_DATA = 'CLEAR_LOGIN_DATA';
export const GLOBAL_DATA = 'GLOBAL_DATA';

//Dashboard
export const DRIVER_DASHBOARD = asyncAction('DRIVER_DASHBOARD');
export const RIDER_DASHBOARD = asyncAction('RIDER_DASHBOARD');
export const BOOKINGS_HISTORY = asyncAction('BOOKINGS_HISTORY');
export const CURRENT_BALANCE = asyncAction('CURRENT_BALANCE');

//User
export const USER_DETAILS = 'USER_DETAILS';
export const CURRENT_LOCATION = 'CURRENT_LOCATION';
export const PROFILE_FETCH = asyncAction('PROFILE_FETCH');
export const PROFILE_SAVE = asyncAction('PROFILE_SAVE');
export const DRIVER_STATUS = asyncAction('DRIVER_STATUS');
export const PROFILE_IMAGE = asyncAction('PROFILE_IMAGE');
export const FETCH_DRIVER = asyncAction('FETCH_DRIVER');
export const DRIVER_SAVE = asyncAction('DRIVER_SAVE');
export const DRIVER_SENT_APPROVAL=asyncAction('DRIVER_SENT_APPROVAL');
export const DRIVER_FILE = asyncAction('DRIVER_FILE');
export const GOOGLE_MAPS_LOADED = 'GOOGLE_MAPS_LOADED';
export const CAR_REGISTER_CHECK = asyncAction('CAR_REGISTER_CHECK');
export const CAR_REGISTER_CHECK_NULL = 'CAR_REGISTER_CHECK_NULL';
export const REFRESH_ZONE = asyncAction('REFRESH_ZONE');
export const LAST_BOOKING_ZONE_ID = asyncAction('LAST_BOOKING_ZONE_ID');
export const EMERGENCY_DETAILS = asyncAction('EMERGENCY_DETAILS');
export const GET_EMERGENCY_DETAILS = asyncAction('GET_EMERGENCY_DETAILS');
export const DELETE_EMERGENCY_DETAILS = asyncAction('DELETE_EMERGENCY_DETAILS');

//Masters
export const COUNTRIES = asyncAction('COUNTRIES');
export const STATIC_PAGE = asyncAction('STATIC_PAGE');
export const ZONES = asyncAction('ZONES');
export const CAR_TYPES = asyncAction('CAR_TYPES');

//Booking
export const PICKUP = 'PICKUP';
export const DROP = 'DROP';
export const BOOKING_DISTANCE = 'BOOKING_DISTANCE';
export const BOOKING_DURATION = 'BOOKING_DURATION';
export const BOOKING_SCREEN_SIZE = 'BOOKING_SCREEN_SIZE';
export const CAR_TYPE = 'CAR_TYPE';
export const ESTIMATED_PRICES = asyncAction('ESTIMATED_PRICES');
export const AVAILABLE_CARS = asyncAction('AVAILABLE_CARS');
export const BOOKING_SAVE = asyncAction('BOOKING_SAVE');
export const BOOKING_CANCEL = asyncAction('BOOKING_CANCEL');
export const SELECTED_LOCATION = 'SELECTED_LOCATION';
export const CHECK_ZONE = asyncAction('CHECK_ZONE');
export const BOOKING_DRIVER = 'BOOKING_DRIVER';
export const CURRENT_RIDE_CHECK = asyncAction('CURRENT_RIDE_CHECK');
export const NEW_CURRENT_RIDE = 'NEW_CURRENT_RIDE';
export const CANCEL_REASONS = asyncAction('CANCEL_REASONS');
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const START_TRIP = asyncAction('START_TRIP');
export const STOP_TRIP = asyncAction('STOP_TRIP');
export const PAYMENT_CONFIRM_TRIP = asyncAction('PAYMENT_CONFIRM_TRIP');
export const CHAT_MESSAGE_SEND = 'CHAT_MESSAGE_SEND';
export const CHAT_MESSAGE_UPDATE = 'CHAT_MESSAGE_UPDATE';
export const SELECT_RIDE = 'SELECT_RIDE';
export const CAR_EST_AMOUNT = 'CAR_EST_AMOUNT';
export const BOOKING_DATA_UPDATE = 'BOOKING_DATA_UPDATE';
export const RATING_SAVE = asyncAction('RATING_SAVE');
export const LATEST_RIDES = asyncAction('LATEST_RIDES');

//Address
export const ADDRESS_LIST = asyncAction('ADDRESS_LIST');

//Noticications
export const NOTIFICATIONS_LIST = asyncAction('NOTIFICATIONS_LIST');
export const NOTIFICATIONS_ACCEPTED = asyncAction('NOTIFICATIONS_ACCEPTED');
export const NOTIFICATIONS_REJECTED = asyncAction('NOTIFICATIONS_REJECTED');
export const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';

//Activity History
export const ACTIVITY_LIST = asyncAction('ACTIVITY_LIST');
export const ACTIVITY_DETAILS = asyncAction('ACTIVITY_DETAILS');

//Toast
export const TOAST_MESSAGE = 'TOAST_MESSAGE';

//Common
export const BREAD_CRUMB = 'BREAD_CRUMB';

//Transactions
export const TRANSACTIONS_LIST = asyncAction('TRANSACTIONS_LIST');
export const WITHDRAW_PENDING_LIST = asyncAction('WITHDRAW_PENDING_LIST');
export const WITHDRAW_UPDATE = asyncAction('WITHDRAW_UPDATE');
export const PAYMENT_LIST = asyncAction('PAYMENT_LIST');

//support
export const TICKET_LIST = asyncAction('TICKET_LIST');
export const CREATE_TICKET = asyncAction('CREATE_TICKET');
export const TICKET_REPLY = asyncAction('TICKET_REPLY');
export const CATEGORY_LIST = asyncAction('CATEGORY_LIST');
export const QUESTION_LIST = asyncAction('QUESTION_LIST');

//settings
export const TIMEZONES = asyncAction('TIMEZONES');
export const FETCH_SETTINGS = asyncAction('FETCH_SETTINGS');
export const UPDATE_SETTINGS = asyncAction('UPDATE_SETTINGS');

//Schedule
export const SCHEDULE_LIST = asyncAction('SCHEDULE_LIST');
export const SCHEDULE_DETAILS = asyncAction('SCHEDULE_DETAILS');
export const SCHEDULE_CANCEL = asyncAction('SCHEDULE_CANCEL');

export const SCHEDULE_PO_LIST = asyncAction('SCHEDULE_PO_LIST');

//Faqs
export const FAQ_LIST = asyncAction('FAQ_LIST');

//FavouriteAddress
export const FAVOURITE_ADDRESS_LIST =  asyncAction('FAVOURITE_ADDRESS_LIST');
export const FAVOURITE_ADDRESS_DETAILS=asyncAction('FAVOURITE_ADDRESS_DETAILS'); 
export const FAVOURITE_ADDRESS_DELETE =  asyncAction('FAVOURITE_ADDRESS_DELETE');

//PageInfo
export const PAGEINFO =  asyncAction('PAGEINFO');
